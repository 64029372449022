<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Importation des leads</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="text-center" v-if="spinner">
          <b-spinner variant="primary" style="width: 15rem; height: 15rem;" label="Spinning"></b-spinner>
        </div>
        <b-form v-else>
          <b-form-group label="Fichier:">
            <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
          </b-form-group>
          <div class="form-group">
            <label>Source:</label>
            <select class="form-control" v-model="import_file.source_id">
              <option v-for="(s,i) in sources" :value="s.id" :key="i">{{s.titre}}</option>
            </select>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      spinner:false,
      import_file:{
        file_name:null,
        file:null,
        source_id:null
      },
      sources:[]
    };
  },
  components: {
  },
  watch: {
  },
  methods: {
    save() {
      console.log(this.import_file)
      this.spinner = true
      this.$store.back.ajax('/lead/import', this.import_file, (res) => {
        if(res.status === true){
          this.spinner = false;
          this.$bvToast.toast(`Votre importation de leads a bien ete faite!`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    handleFile(e){
      const fileUploaded = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.import_file.file = e.target.result;
        this.import_file.file_name = fileUploaded.name;
      }
      reader.readAsDataURL(fileUploaded);
    }
  },
  computed: {

  },
  beforeMount(){
    this.$store.back.ajax('/parametrage/liste?module=Source&page=1&limit=20&sort=%7B%22field%22:%22id%22,%22value%22:%22DESC%22%7D&search=null', null, (res) => {
      this.sources = res.data;
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
